import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import img1 from "../images/404-img-1.svg";

const NotFoundPage = () => (
  <Layout css="NotFoundPage">
    <SEO title="Page not Found"
         description={""}/>
    <div className={"container hero section-padding-wrapper text-align-center"}>
      <img alt={""} src={img1} className={"m-pic-align"} id={"img1"}/>
      <h1><span className={"red"}>OH NO!</span> LOOKS LIKE YOU DIDN'T MAKE IT TO WHERE YOU WERE HEADED</h1>
      <p className={"subtitle"}>It's okay, we all get lost sometime. What matter is getting back on the right path. Let us help you that :)</p>
      <a href={"/marketing-services"} className={"button-red"}>ALL SERVICES</a>
    </div>
  </Layout>
);

export default NotFoundPage
